export function phoneNumberOption(phone: string) {
  return {
    blocks: !phone || phone?.startsWith("+") ? [3, 3, 3, 3] : [4, 3, 3],
    delimiter: " ",
  };
}

export const phonePlaceholder = "0412 345 678";

export const getCurrencyOptions = ({
  numeral = true,
  numeralThousandsGroupStyle = "thousand",
  numeralPositiveOnly = true,
}: {
  numeral?: boolean;
  numeralThousandsGroupStyle?: NumeralThousandsGroupStyle;
  numeralPositiveOnly?: boolean;
}): CurrencyOptions => ({
  numeral,
  numeralThousandsGroupStyle,
  numeralPositiveOnly,
  noImmediatePrefix: true,
});
type NumeralThousandsGroupStyle = "thousand" | "lakh" | "wan";

type CurrencyOptions = {
  numeral: boolean;
  numeralThousandsGroupStyle: NumeralThousandsGroupStyle;
  numeralPositiveOnly: boolean;
  noImmediatePrefix: boolean;
};
