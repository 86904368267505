import {
  hasLowerCaseRegex,
  hasUpperCaseRegex,
  hasSpecialCharsRegex,
  hasDigitsRegex,
} from "@/helpers/regex";
import { allowedDocumentMimeTypes } from "@/constants";

export const hasLowerAndUpperCase = (value: string) =>
  hasLowerCaseRegex.test(value) && hasUpperCaseRegex.test(value);
export const hasPasswordLength = (value: string) =>
  value.length >= 4 && value.length <= 16;
export const hasSpecialChars = (value: string) =>
  hasSpecialCharsRegex.test(value);
export const hasDigits = (value: string) => hasDigitsRegex.test(value);
export const hasNumberAndSymbol = (value: string) =>
  hasDigits(value) && hasSpecialChars(value);
export const validatePassword = (value: string) =>
  hasLowerAndUpperCase(value) &&
  hasPasswordLength(value) &&
  hasNumberAndSymbol(value);
export const validateDocumentMimeType = (mimeType: string): boolean =>
  allowedDocumentMimeTypes.includes(mimeType);
export const validPhoneNumber = (value: string) =>
  /^(\+61|0)4\d{8}$/.test(value);
export const validOptionalPhoneNumber = (value: string) => {
  if (value) {
    return validPhoneNumber(value);
  }
  return true;
};
