import { ref, Ref, watch } from "vue";
import router from "@/router";
import { UserRoles } from "@/enums/UserRoles";

export function useRoleByRouteInAdmin(): { roleByRoute: Ref<UserRoles> } {
  const roleByRoute = ref("" as UserRoles);

  watch(
    () => router.currentRoute.value,
    (value) => {
      roleByRoute.value =
        value.meta.role && value.meta.role === UserRoles.LAWYER
          ? UserRoles.LAWYER
          : UserRoles.LANDOWNER;
    },
    {
      immediate: true,
    }
  );

  return { roleByRoute };
}
